.myfileupload-buttonbar input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  border: solid transparent;
  border-width: 0 0 100px 200px;
  opacity: 0;
  filter: alpha(opacity=0);
  -o-transform: translate(250px, -50px) scale(1);
  -moz-transform: translate(-300px, 0) scale(4);
  direction: ltr;
  cursor: pointer;
}
.myui-button {
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 4px 15px 4px 15px;
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: visible;
  overflow: hidden;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.myui-button:hover {
  border: 1px solid #40a9ff;
  color: #40a9ff;
}
