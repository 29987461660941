.cron-info-box {
  width: 650px;
  background: #172b4d;

  color: #ffffff;

  border-radius: 10px;
  
  @media (max-width: 920px) {
    width: 450px;
  }
  
  @media (max-width: 629px) {
    margin-bottom: 20px;
  }

  .ant-picker {
    border: 1px solid #ffffff;
    background: #172b4d;

    border-radius: 8px;

    .ant-picker-input {
      input {
        color: #ffffff;
      }

      span {
        color: #ffffff;
      }
    }
  }
  
  .ant-picker-range {
    svg {
      color: #ffffff;
    }
    .anticon-close-circle {
      background: #172b4d;
      width: 15px;
      height: 15px;
    }
  }

  td {
    padding: 6px 0px 6px 0px !important;
  }
  
  .line {
    border-top: 1px solid #a6a1c8;
  }
}

.cron-info-data {
  .data-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .data-cell {
    padding: 5px;
    flex: 0 0 100%;
    
    @media (min-width: 1100px) {
      flex: 0 0 50%;
    }

    i {
      margin-right: 0.5rem;
    }
  }
}
