.spin {
  animation-name: spin;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.po-opener {
  button {
    width: 100%;
    border: none;
    background: none;

    padding: 0.5rem;
  }
}
