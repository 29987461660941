.get-tickets-container {
  width: 30%;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;

  position: relative;

  .main-label {
    font-weight: 500;
    font-size: 32px;
    line-height: 41px;

    color: #000000;
  }

  .info-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    color: #000000;
  }

  button {
    background: #ce4275;
    border-radius: 14px;
    border: 0;
    color: white;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    color: #ffffff;

    width: 35%;
    height: 3.25rem;
  }

  button:hover {
    background: #ea427f;
  }

  button:disabled {
    background: #bd748e;
  }
}
