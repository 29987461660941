.Table {
  $tr-bg: background-color 0.15s ease;
  $bg-color: #f6f9fc;

  width: '100%';
  display: 'table';
  border-spacing: 0;
  border-collapse: 'separate';
  text-align: 'center';

  thead {
    tr {
      th {
        cursor: pointer;
        transition: $tr-bg;

        top: 0;
        left: 0;
        z-index: 2;
        position: 'sticky';

        &:hover {
          background-color: darken($bg-color, 1%);
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;
      transition: $tr-bg;

      &:hover {
        background-color: $bg-color;
      }

      td {
        max-width: 135px;
        white-space: normal;
        text-align: center;
        vertical-align: middle;

        .btn-cell {
          border: none;
          background: none;
          color: #337ab7;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  position: relative;
}

.tooltip {
  position: absolute;
  top: 15px;
  left: 0px;

  > .tooltip-inner {
    padding: 6px 8px;
    max-width: 392px;
    max-height: fit-content;
  }
}

.w-10-rem {
  width: 14rem;
}

.anticon {
  vertical-align: 2px !important;
}
