.pre-popover-value {
    font-family: DM Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-style: normal;
    color: #525f7f;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.7;
    text-wrap: wrap;
}

.pre-modal-value {
    text-wrap: wrap;
    word-break: break-all;
}

.popover {
    max-width: 30rem !important;
}