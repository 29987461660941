.add-url {
  width: 10rem;
}

@media (max-width: 992px) {
  .add-url {
    width: 100%;
  }
}

.unstyled-button {
  width: 100%;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
  justify-content: center;
  text-align: start;
}