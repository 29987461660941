.edit-cart-info-btn {
  border: none;
  background: none;
  padding: 0;
}

.active-filter {
  background: #172b4d !important;
  color: white !important;
}

.test {
  animation: my 1s ease-in-out infinite alternate;
}

@keyframes my {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.info-accepted {
  min-width: 120px;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 6px;
  background-color: #2dce89;
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: #fafafa;
  font-weight: 500;
  text-align: right;
  padding: 4px 8px;
}
