.frame {
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  font-weight: 800;
  font-size: 12px;
  width: fit-content;
}

.primary-border {
  border: 1px solid #5e72e4;
}

.grey-border {
  border: 1px solid #525f7f;
}
