.ticket-type-form {
  border: 1px solid #e6eef2;
  border-radius: 10px;

  padding: 1rem 1.5rem 1rem 1.5rem;

  .ant-upload {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
