.Navbar {
  background-color: darken(#f8f9fe, 3%);

  &__btnsContainer {
    width: 100%;
    overflow-x: scroll;
    justify-content: start !important;

    .tabBtn {
      &:hover {
        transform: none;
      }

      &.active {
        background-color: #f8f9fe;
      }
    }
  }
}
