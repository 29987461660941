.tabs-btn-left {
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  text-transform: uppercase;
  border: 1px solid #dfe5ec;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;
  padding: 15px;
  flex: 1 0 0;
  cursor: pointer;
  text-align: center;
  outline: none;
  width: 300px;
}

.tabs-btn-right {
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  text-transform: uppercase;
  border: 1px solid #dfe5ec;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  padding: 15px;
  flex: 1 0 0;
  cursor: pointer;
  text-align: center;
  outline: none;
  width: 300px;
}

.is-active-tabs-btn-left {
  background: #5e72e4;
  color: white;
  border: none;
}

.is-active-tabs-btn-right {
  background: #5e72e4;
  color: white;
  border: none;
}

.revert-btn {
  border: none;
  background: none;
  margin-bottom: 8px;
}

.number-validation {
  color: #D13053;
}
